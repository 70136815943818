import { RouteName } from '@leon-hub/routing-config-names';

import type { AsyncAbstractPrefetchResult } from '@core/router';
import { AbstractPrefetch } from '@core/router';
import { useSiteConfigStore } from '@core/site-config';

import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';

export default class SportradarStatisticsWidgetPrefetch extends AbstractPrefetch {
  async prefetch(): AsyncAbstractPrefetchResult {
    if (!useSiteConfigStore().isSportradarStatisticsEnabled) {
      return { name: RouteName.ERROR_NOT_FOUND_404 };
    }

    await useSportlineWidgetsStore().fetchSportradarStatisticsConfig();
  }
}
